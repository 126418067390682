function Stars() {
  // const backgroundImg = `${process.env.PUBLIC_URL}/images/background.png`;
  return (
    <div className="stars -z-50 ">
      {Array.from({ length: 50 }).map((_, i) => (
        <div className="star" key={i + 0} />
      ))}
    </div>
  );
}

export default Stars;
