import Button from "../Button";
import Stars from "../Stars";
import ImgModal from "../ImgModal";
import { useState } from "react";

const imgUrl = [
  "/images/noobie/noobie1.png",
  "/images/noobie/noobie2.png",
  "/images/noobie/noobie3.png",
  "/images/noobie/noobie4.png",
];

function Noobie() {
  const [isOpen, setIsOpen] = useState(false);
  // const profileImg = `${process.env.PUBLIC_URL}/images/profile.png`;

  return (
    <>
      <div className="w-full h-screen py-[60px] px-[20px] bg-cover bg-center">
        <div className="max-w-[500px] min-w-[240px] w-full h-full mx-auto rounded-[40px] bg-cover bg-center ">
          <main className="  max-w-[750px] w-full h-full mx-auto  rounded-[40px] overflow-hidden  bg-slate-50 bg-opacity-60">
            <div className="h-full w-full py-[40px] overflow-y-scroll rounded-[40px]">
              <div className=" flex flex-col gap-[20px] items-center ">
                <section className="w-full flex flex-col gap-[10px] ">
                  <button
                    type="button"
                    onClick={() => {
                      setIsOpen(true);
                      console.log(41561156);
                    }}
                    className="relative mx-auto w-[150px] h-[150px] group/img"
                  >
                    <div className="relative mx-auto w-[150px] h-[150px] rounded-full overflow-hidden ">
                      <img
                        src="/images/noobie/profile.png"
                        alt="프로필"
                        className="relative w-[150px] h-[150px] z-10 rounded-full  bg-cover bg-center group-hover/img:scale-105 transition duration-[500ms] ease-in-out delay-0"
                      />
                    </div>
                    <div className="absolute w-[150px] h-[150px] z-0 top-[5px] left-[5px] rounded-full bg-indigo-500 group-hover/img:bg-indigo-600 transition duration-[500ms] ease-in-out delay-0" />
                    <div className="absolute w-[150px] h-[150px]  z-20 top-[0px] left-[0px] rounded-full bg-white opacity-0  transition duration-[500ms] ease-in-out delay-0" />
                  </button>
                  <ImgModal
                    open={isOpen}
                    onCancel={() => {
                      setIsOpen(false);
                    }}
                    urls={imgUrl}
                  />
                  <div className="flex flex-col gap-[5px]">
                    <h1 className="text-[30px] font-semibold text-center">누비</h1>
                    <div className="px-[30px] flex flex-col">
                      <h1 className="text-[20px] font-[600] text-center">MBTI: ISTJ</h1>
                      <h1 className="text-[20px] font-[600] text-center text-balance">Discord: noobie_kun</h1>
                    </div>
                  </div>
                </section>
                <section className="w-full flex flex-col gap-[20px] items-center pb-[20px]">
                  <Button href="https://vrchat.com/home/user/usr_6cc504fc-90f8-43e6-9c1d-c4219f2a7f42">
                    <div className="w-full flex flex-row items-center justify-between">
                      <div className="w-[30px] h-[30px]" />
                      <p className="text-[20px] font-semibold">VRChat</p>
                      <img className="w-[25px] h-[25px]" src="/images/vr.svg" alt="트위터" />
                    </div>
                  </Button>
                  <Button href="https://vtalk.be/">
                    <div className="w-full flex flex-row items-center justify-between">
                      <div className="w-[30px] h-[30px]" />
                      <p className="text-[20px] font-semibold">VTalk</p>
                      <p className="text-[21px] font-black">VT</p>
                    </div>
                  </Button>
                  <Button href="https://x.com/NOOBIE_KUN">
                    <div className="w-full flex flex-row items-center justify-between">
                      <div className="w-[30px] h-[30px]" />
                      <p className="text-[20px] font-semibold">Twitter</p>
                      <img className="w-[25px] h-[25px]" src="/images/X.svg" alt="트위터" />
                    </div>
                  </Button>
                  <Button href="https://pushoong.com/ask/8253303490">
                    <div className="w-full flex flex-row items-center justify-between">
                      <div className="w-[30px] h-[30px]" />
                      <p className="text-[20px] font-semibold">푸슝! 익명질문</p>
                      <img className="w-[25px] h-[25px]" src="/images/푸슝.webp" alt="푸슝" />
                    </div>
                  </Button>
                  <Button href="https://discord.gg/5p98YwQUzq">
                    <div className="w-full flex flex-row items-center justify-between">
                      <div className="w-[30px] h-[30px]" />
                      <p className="text-[20px] font-semibold">만남의 광장</p>
                      <img className="w-[25px] h-[25px]" src="/images/discord.svg" alt="디코방" />
                    </div>
                  </Button>
                </section>
                {/* <section className="flex flex-col gap-[5px] items-center">
                <img className="w-[130px] h-[130px]" src="/images/QRKakao.png" alt="QR" />
              </section> */}
              </div>
            </div>
          </main>
        </div>
      </div>

      <Stars />
    </>
  );
}

export default Noobie;
// bg-fixed bg-center
