interface ButtonProps {
  href: string;
  children: string | JSX.Element;
  color?: string | undefined;
}
function Button({ href, children, color }: ButtonProps) {
  const buttonColor = color ? color : "bg-indigo-500 group-hover:bg-indigo-600";
  return (
    <div className="w-full h-full pl-[20px] pr-[30px] z-0">
      <a href={`${href}`} target="_blank" rel="noreferrer" className="relative block group">
        <div className="w-full h-[50px] px-[20px] flex flex-row  z-0 rounded-[5px] bg-[#d8d8d8] group-hover:translate-x-[3px] group-hover:translate-y-[3px] group-active:translate-x-[6px] group-active:translate-y-[6px]">
          {children}
        </div>
        <div
          className={`${buttonColor} absolute top-[10px] left-[10px] w-full h-[50px] -z-10  rounded-[5px] transition duration-[500ms] ease-in-out delay-0`}
        ></div>
      </a>
    </div>
  );
}

export default Button;
// <a href={`${href}`}>
//     {children}
//     </a>;
