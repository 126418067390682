import { Carousel, Modal } from "antd";

interface ImgModalProps {
  open: boolean;
  onCancel: () => void;
  urls: string[];
}
function ImgModal({ open, onCancel, urls }: ImgModalProps) {
  return (
    <Modal
      open={open}
      closable={false}
      onCancel={onCancel}
      footer={[]}
      width={1200}
      className="aspect-video bg-transparent"
      wrapClassName=""
    >
      <Carousel
        dotPosition="bottom"
        arrows
        draggable
        className=" w-full h-full min-h-0 overflow-hidden aspect-video bg-transparent "
      >
        {urls.map((i, index) => (
          <div key={`${index + 0}`} className="w-full aspect-video bg-transparent">
            <img alt="이미지" src={i} className="h-full object-contain mx-auto" />
          </div>
        ))}
      </Carousel>
    </Modal>
  );
}

export default ImgModal;
