import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import Noobie from "./pages/Noobie";
import Page404 from "./pages/Page404";
import PrickEar from "./pages/PrickEar";
import Envyrinbe from "./pages/Envyrinbe";
import Bbangnyang from "./pages/Bbangnyang";
import JinSeol from "./pages/JinSeol";
import Timoria from "./pages/Timoria";

function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Carousel: {
            arrowSize: 30,
          },
          Modal: {
            contentBg: "rgba(248, 250, 252, 0)",
            boxShadow: "0",
          },
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/noobie" element={<Noobie />} />
          <Route path="/prick_ear" element={<PrickEar />} />
          <Route path="/envyrinbe" element={<Envyrinbe />} />
          <Route path="/bbangnyang" element={<Bbangnyang />} />
          <Route path="/jinSeol" element={<JinSeol />} />
          <Route path="/timoria" element={<Timoria />} />
          <Route path="/*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
