import Button from "../Button";
import Stars from "../Stars";
import ImgModal from "../ImgModal";
import { useState } from "react";

const imgUrl = [
  "/images/timoria/timoria1.png",
  "/images/timoria/timoria2.png",
  "/images/timoria/timoria3.png",
  "/images/timoria/timoria4.png",
];

function Timoria() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="w-full h-screen py-[60px] px-[20px] bg-cover bg-center">
        <div className="max-w-[500px] min-w-[240px] w-full h-full mx-auto rounded-[40px] bg-cover bg-center ">
          <main className="  max-w-[750px] w-full h-full mx-auto  rounded-[40px] overflow-hidden  bg-slate-50 bg-opacity-60">
            <div className="h-full w-full py-[40px] overflow-y-scroll rounded-[40px]">
              <div className=" flex flex-col gap-[20px] items-center ">
                <section className="w-full flex flex-col gap-[10px] ">
                  <button
                    type="button"
                    onClick={() => {
                      setIsOpen(true);
                      console.log(41561156);
                    }}
                    className="relative mx-auto w-[150px] h-[150px] group/img"
                  >
                    <div className="relative mx-auto w-[150px] h-[150px] rounded-full overflow-hidden ">
                      <img
                        src="/images/timoria/timoriaProfile1.png"
                        alt="프로필"
                        className="relative w-[150px] h-[150px] z-10 rounded-full  bg-cover bg-center group-hover/img:scale-105 transition duration-[500ms] ease-in-out delay-0"
                      />
                    </div>
                    <div className="absolute w-[150px] h-[150px] z-0 top-[5px] left-[5px] rounded-full opacity-80 bg-sky-400 group-hover/img:bg-sky-500 transition duration-[500ms] ease-in-out delay-0" />
                    <div className="absolute w-[150px] h-[150px]  z-20 top-[0px] left-[0px] rounded-full bg-white opacity-0  transition duration-[500ms] ease-in-out delay-0" />
                  </button>
                  <ImgModal
                    open={isOpen}
                    onCancel={() => {
                      setIsOpen(false);
                    }}
                    urls={imgUrl}
                  />
                  <div className="flex flex-col gap-[5px]">
                    <h1 className="text-[30px] font-semibold text-center">Timori_a</h1>
                    <div className="text-[20px] font-[600] text-center"> Discord : dila_bi</div>
                    <div className="text-[20px] font-[400] text-center">"The neon lights, the darkness invites us"</div>
                  </div>
                </section>
                <section className="w-full flex flex-col gap-[20px] items-center pb-[20px]">
                  <Button
                    href="https://vrchat.com/home/user/usr_699aeac0-1f02-41e4-9d8a-1203ed4aa893"
                    color="opacity-80 bg-sky-400 group-hover:bg-sky-500"
                  >
                    <div className="w-full flex flex-row items-center justify-between">
                      <div className="w-[30px] h-[30px]" />
                      <p className="text-[20px] font-semibold">VRChat</p>
                      <img className="w-[25px] h-[25px]" src="/images/vr.svg" alt="vr" />
                    </div>
                  </Button>

                  <Button href="https://x.com/timori_aa" color="opacity-80 bg-sky-400 group-hover:bg-sky-500">
                    <div className="w-full flex flex-row items-center justify-between">
                      <div className="w-[30px] h-[30px]" />
                      <p className="text-[20px] font-semibold">Twitter</p>
                      <img className="w-[25px] h-[25px]" src="/images/X.svg" alt="트위터" />
                    </div>
                  </Button>
                </section>
              </div>
            </div>
          </main>
        </div>
      </div>

      <Stars />
    </>
  );
}

export default Timoria;
